// ==========================================================================
// Helper Classes
// ==========================================================================

%all-caps {
  font-weight: 600;
  // letter-spacing: .06em;
  text-transform: uppercase;
}

//  clearfix
%clearfix {
  *zoom:1;
  &:before, &:after { content: " "; display: table; }
  &:after { clear: both; }
}

// ==========================================================================
// Image replacement
// ==========================================================================

%ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;/* IE 6/7 fallback */
  *text-indent: -9999px;
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
  }
}

%no-bullets {
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
}

// make sure container is [position: relative]
// http://codepen.io/shshaw/full/gEiDt
%absolute-center {
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

// Hide from both screenreaders and browsers: h5bp.com/u

%hidden {
  display: none !important;
  visibility: hidden;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v

%visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// Hide visually and from screenreaders, but maintain layout

%invisible {
  visibility: hidden;
}

// Screen reader text

.srt {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

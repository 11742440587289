$christi: #55ab0f;
$pine-cone: #716258;
$green-valua: #afbc26;


.login-box {
	border-radius: 4px; 
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
}

.login-logo {

	margin-bottom: 0;
	background-color: #fff;
	padding-top: 20px;

	img {
		width: 70%;
	}
}
.skin-dyn {
	.login-box-body {
		.btn-primary {
			background-color: $christi;
			border-color: $christi;
		}

		.form-control:focus {
			border-color: $christi !important;
		} 
	}
}


.skin-valenclinic {
	.login-box-body {
		.btn-primary {
			background-color: $pine-cone;
			border-color: $pine-cone;
		}

		.form-control:focus {
			border-color: $pine-cone !important;
		} 
	}
}

.skin-valuaclinic {
	.login-box-body {
		color: #000;

		.btn-primary {
			background-color: $green-valua;
			border-color: $green-valua;
		}
		
		.form-control {
			color: #000;
		}

		.form-control:focus {
			border-color: $green-valua !important;
		} 
	}
}
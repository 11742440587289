// ==========================================================================
// = Global Variables
// ==========================================================================


// use bootstrap
$bootstrap                    : false;

// use font-awesome
$font-awesome                 : false;

// use bourbon.io
$bourbon                      : false;

// use neat grid
$neat                         : false;

// use jeet
$jeet                         : false;

// use susy
$susy_use                     : true;

// defaults susy
$susy: (
  flow: ltr,
  math: fluid,
  output: float,
  gutter-position: after,
  container: 1140px,
  container-position: center,
  columns: 12,
  gutters: .25,
  column-width: false,
  global-box-sizing: content-box,
  last-flow: to,
  debug: (
    // image: show-columns,
    color: rgba(#66f, .25),
    output: background,
    toggle: top right,
  ),
  use-custom: (
    background-image: true,
    background-options: false,
    box-sizing: true,
    clearfix: false,
    rem: true,
  )
);

/**
 * CSS layout debugging
 * @link http://pesticide.io/
 * @type boolean
 */

$pesticide-debug              : false;

/**
 * Define width for browsers w/out media query support
 * @link http://jakearchibald.github.com/sass-ie/
 * @type boolean
 */

$fix-mqs                      : false !default;

/**
 * Turn on/off IE specific styles
 * @link http://jakearchibald.github.com/sass-ie/
 * @type boolean
 */

$old-ie                       : false !default;

// Color Scheme
// examples based on the official Sass styleguide
// =============

// Descriptive colors:
$black                        : #000;
$white                        : #fff;
$white-cream                  : #fdfbf2;

$blue-dark                    : #4a1c1b;
$red-dark                     : #bd4948;
$cream                        : #e1e3cd;

$blue-menu                    : #264e82;


// Base Sizes
// ==========

/**
 * Base font size in used in _mixins.scss
 * @type number
 */

$base-font-size               : 16;

/**
 * Base line-height in used in _mixins.scss
 * @type number
 */

$base-line-height             : 24;


// Font Stacks
// ===========


/**
 * define font stack used for sans-serifs
 * @type string
 */

$font-family-sans             : Futura, 'Trebuchet MS', Arial, sans-serif;

/**
 * define font stack used for serifs
 * @type string
 */

$font-family-serif            : 'Goudy Bookletter 1911', serif;


/**
 * define font stack used for headings
 * @requires {variable} $font-family-serif
 * @type string
 */

$font-family-display          : 'blue_plate_special_nfregular';

/**
 * define font stack used for paragraphs
 * @requires {variable} font-family-sans
 * @type string
 */

$font-family-default          : $font-family-sans;

// Font Weights
// ============

$font-weight-light            : 300;
$font-weight-normal           : 400;
$font-weight-semibold         : 700;
$font-weight-bold             : 900;


// Breakpoints
// There are no common breakpoints; examples based on the official Sass styleguide
// You'll need to define your own breakpoints to suit your design
// ============

$mobile-small                 : 240px;
$mobile-large                 : 320px;
$tablet-small                 : 480px;
$tablet-large                 : 768px;
$screen-small                 : 1024px;
$screen-large                 : 1280px;

/**
 * Define z-indexes for various elements
 * @link http://www.sitepoint.com/using-sass-maps/
 * @type number
 */

// z-index reference
$zindex: (
  modal                       : 9000,
  overlay                     : 8000,
  dropdown                    : 7000,
  header                      : 6000,
  footer                      : 5000
);
